import React, { memo, useState, useEffect, useRef } from "react";
import "./DocIdStep.scss";
import Camera, { IMAGE_TYPES } from "react-html5-camera-photo";
import Footer from "templates/FormTemplate/components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import { postImageForm } from "service/newNaturalClient/formOne";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { useDispatch } from "react-redux";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import IconId from "../../../../assets/icons/id-scan.svg";

// Función para recortar la imagen
const cropImage = (dataUri, cropWidth, cropHeight, cropX, cropY) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.fillStyle = "#0000008C";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 350, 70, 1460, 1080, 0, 0, 1920, 1380);

      resolve(canvas.toDataURL("image/jpeg"));
    };
    img.src = dataUri;
  });
};

// Componente para renderizar la cámara
const RenderCamera = ({ videoConstraints, onTakePhoto }) => {
  return videoConstraints === "user" ? (
    <Camera
      height={"1200px"}
      idealResolution={{ width: 1920, height: 1080 }}
      onTakePhoto={onTakePhoto}
      idealFacingMode={"user"}
      isImageMirror={false}
      imageType={IMAGE_TYPES.JPG}
      isMaxResolution={true}
      imageCompression={1}
    />
  ) : (
    <Camera

      idealResolution={{ width: 1920, height: 1080 }}
      onTakePhoto={onTakePhoto}
      idealFacingMode={"environment"}
      isImageMirror={false}
      imageType={IMAGE_TYPES.JPG}
      isMaxResolution={true}
      imageCompression={1}
    />
  );
};

const DocIdStep = memo(() => {
  const [videoConstraints] = useState("environment");
  const [Picture, setPicture] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [firstScreen, setFirstScreen] = useState("FIRST");
  const dispatch = useDispatch();

  const clickOnHideCameraButton = () => {
    const button = document.getElementById("outer-circle");
    if (button) button.click();
    setFirstScreen("THREE");
  };

  const onTakePhoto = React.useCallback(async (dataUri) => {
    const croppedImage = await cropImage(dataUri, 1640, 1480, 0, 0);
    sessionStorage.setItem("img-preview", croppedImage);
    setPicture(croppedImage);
    setFirstScreen("THREE");
  }, []);

  const handleRepit = React.useCallback(() => {
    sessionStorage.removeItem("img-preview");
    setFirstScreen("SECOND");
    setPicture(undefined);
  }, []);

  const handleSentPhoto = async () => {
    setLoading(true);
    const body = {
      requestNumber: sessionStorage.getItem("requestNumber"),
      image: sessionStorage.getItem("img-preview"),
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      const res = await postImageForm("document", formData);
      dispatch(setNewNaturalStep(parseInt(res?.data?.nextView)));
      dispatch(setFormTrackInfo(res?.data));
    } catch (error) {
      console.log("DOCUMENTO:", error);
      ErrorCToast(error);
      setFirstScreen("SECOND");
    } finally {
      setLoading(false);
    }
  };

  const canvasRef = useRef(null);

  useEffect(() => {
    if (firstScreen === "SECOND") {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Ajusta el tamaño del canvas
      canvas.width = width;
      canvas.height = height;

      // Coordenadas y dimensiones del área de enfoque
      const rectX = 20; // Desplazado un poco del borde
      const rectY = height * 0.1;
      const rectWidth = width * 0.9; // Ajustado para que no ocupe el ancho completo
      const rectHeight = height * 0.35; // Más alto para parecerse a la imagen

      // Dibuja el área oscurecida
      ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
      ctx.fillRect(0, 0, width, height);

      // Dibuja el rectángulo con esquinas redondeadas
      const borderRadius = 20;

      function drawRoundedRect(x, y, w, h, radius) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + w - radius, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + radius);
        ctx.lineTo(x + w, y + h - radius);
        ctx.quadraticCurveTo(x + w, y + h, x + w - radius, y + h);
        ctx.lineTo(x + radius, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
      }

      // Limpia el área del enfoque dentro del rectángulo
      drawRoundedRect(rectX, rectY, rectWidth, rectHeight, borderRadius);
      ctx.clip(); // Esto es necesario para evitar que se dibuje fuera de las esquinas redondeadas
      ctx.clearRect(rectX, rectY, rectWidth, rectHeight);

      // Añadir sombra al rectángulo
      ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 5;
      ctx.shadowOffsetY = 5;

      // Dibuja el borde naranja del rectángulo con esquinas redondeadas
      ctx.strokeStyle = "#F75C00"; // Color del borde (naranja)
      ctx.lineWidth = 2; // Grosor del borde

      // Dibuja el borde con las esquinas redondeadas
      drawRoundedRect(rectX, rectY, rectWidth, rectHeight, borderRadius);
      ctx.stroke();

      // Restablecer la sombra para evitar que afecte otros elementos
      ctx.shadowColor = "transparent";
    }
  }, [firstScreen]);
  

  return (
    <div className="vista">
      {firstScreen === "SECOND" && (
        <>
          <Navbar title="Cédula" noBackbutton={true} />
        
            <div style={{
          
              alignItems: "center",
              alignContent: "center",
              // position: "absolute",
              marginLeft: "-25%",
              marginTop: "5%",
              marginRight: "30%",
              width: "150%",
              height: "100%",
              zIndex: 1,
         
         
              
            }}
             className="tomar">
              <RenderCamera
                videoConstraints={videoConstraints}
                onTakePhoto={onTakePhoto}
              />
            </div>

            <canvas
              ref={canvasRef}
              width={window.innerWidth}
              height={window.innerHeight}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                padding: 0 ,
                width: "100vw",
                height: "100vh",
                pointerEvents: "none", // Asegura que el canvas no bloquee las interacciones
              }}
            />
   
          <Footer style={{ overflow: "hidden"}} onClick={clickOnHideCameraButton} camera />
        </>
      )}
     {firstScreen === "THREE" && (
        <>
          <Navbar title="Captura de cédula" noHomebutton={true}  />
      
            <div className="container-preview">
            <img  src={Picture} alt="preview" className="image-preview" />
       
            </div>
          <Footer
            onClick={handleSentPhoto}
            loading={loading}
            preview
            handleRepit={handleRepit}
          />
        </>
      )}
      {firstScreen === "FIRST" && (
        <div>
          <Navbar title="Cédula" noBackbutton={true} />
          <div
            style={{
              height: "250px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ height: "150px" }}></div>
            <img id="face-logo" src={IconId} />
            <p style={{ marginTop: "16px" }}>
              Prepárese para escanear su
              <br />
              Cédula de Panamá
            </p>
          </div>
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <button
              style={{ marginTop: "100px" }}
              className="big-button"
              onClick={() => {
                setFirstScreen("SECOND");
              }}
            >
              Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

DocIdStep.displayName = "DocIdStep";
export default DocIdStep;