import { memo } from "react";
import WelcomeTemplate from "templates/WelcomeTemplate/WelcomeTemplate";
import WelcomeO from "../../../assets/icons/welcome-two.svg"
import PointWT from "../../../assets/icons/point-welT.svg"
import { Text } from "@chakra-ui/layout";
import { useNavigate } from "react-router";

import "./Welcome.scss"
const WelcomeTwo = memo(() => {
    const navigate = useNavigate();
    const handleClick = () => {
        return navigate("/welcome-three")
    }
    const handleClickOne = () => {
        return navigate("/welcome-one")
    }
    return (
        <WelcomeTemplate onClick={handleClick} showIcon={true} onClickOne={handleClickOne}>
            <img src={WelcomeO} alt="welcome-one" />
            <img src={PointWT} alt="welcome-one" />
            <Text color="pinkBen.500" fontSize="16" fontWeight="600">Sin costo de apertura ni cargos mensuales</Text>
            <Text color="cBlueGray.700" fontSize="14" fontWeight="500">Tu dinero está seguro en Banco Nacional de Panamá</Text>



        </WelcomeTemplate>
    );
});
WelcomeTwo.displayName = "WelcomeTwo";
export default WelcomeTwo;